import * as React from "react"

export default function Report() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        style={{ width: "100%", height: "100%" }}
        src="https://main.d26upkwb80p4qo.amplifyapp.com/"
        frameborder="0"
      ></iframe>
    </div>
  )
}
